/* ********************************************************************************************
    Reset
*********************************************************************************************** */
body {
  font-family: "Arial", sans-serif;
  background-color: #f2f6f5;
  color: #666666;
  margin: 0;
}

.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu,
.site-logo li .brand-text,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.nav.metismenu,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Arial", sans-serif;
}
.h1,
.h2,
.h3,
body,
h1,
h2,
h3,
html {
  font-weight: 400;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--body-color);
  font-weight: bold;
}
.text-default {
  color: #666666 !important;
}
.text-muted {
  color: #b2b2b2 !important;
}
p {
  color: #333333;
}
.text-dark {
  color: #333 !important;
}
.text-black {
  color: #000 !important;
}
.text-primary-custom {
  color: var(--body-color) !important;
}
.bg-primary,
.bg-primary-custom {
  background-color: var(--body-color) !important;
}

.o-contain {
  object-fit: contain;
}
.o-cover {
  object-fit: cover;
}
svg {
  vertical-align: middle;
}
.w-100p,
.w-100p ~ .select2-container {
  width: 100% !important;
}

.w-60 {
  max-width: 60px;
}
.w-80 {
  max-width: 80px;
}
.w-385 {
  width: 385px !important;
}
a,
.pointer {
  cursor: pointer !important;
}
.actions > li {
  vertical-align: top;
}
.tab-pane .actions.top-right {
  top: 12px;
}
strong {
  font-weight: bold;
}
.overflow-auto {
  overflow: auto;
}
.tgl + .tgl-btn {
  width: 2.55em;
  height: 1.35em;
}
.border {
  border: 1px solid rgba(210, 221, 234, 0.5) !important;
}
.lh-16 {
  line-height: 16px;
}
.lh-18 {
  line-height: 18px;
}
.lh-58 {
  line-height: 58px;
}
.lh-normal {
  line-height: normal !important;
}
button.disabled {
  pointer-events: none;
}
.w-170 {
  width: 170px;
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-15 {
  font-size: 15px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.min-h-100p {
  min-height: 100%;
}
.mt-n-2 {
  margin-top: -2rem;
}
.shadow-sm {
  box-shadow: 0.54px 0.54px 1.89px rgb(0 0 0 / 10%) !important;
}
i {
  color: #666666;
}
.border-primary {
  border-color: var(--body-color) !important;
}
div.p-5 {
  padding: 3rem !important;
}
.page-title {
  font-size: 1.75rem!important;
  padding-left: 40px;
  margin-left: 4px;
  margin-bottom: 1.5rem;
}
.text-decoration-none {
  text-decoration: none !important;
}
.min-h-235 {
  min-height: 235px;
}

/* ********************************************************************************************
    Page Header & Page Content
*********************************************************************************************** */
.page-header {
  padding: 20px 20px 0;
}
.page-header h1 {
  font-weight: 600;
  font-size: 1.875rem;
}
.page-content {
  padding: 20px;
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  line-height: 1.3;
}
.card.card-tabs .card-header .nav.nav-tabs li:first-child a.active {
  border-radius: 0.25rem 0 0 0;
}

.view-badge {
  vertical-align: 19%;
  margin-left: 10px;
  display: inline-block;
  padding: 4px 10px 4px;
}
.badge-orange {
  background-color: #f39c12 !important;
  color: rgba(255, 255, 255, 0.85);
}
.badge-green {
  background-color: #70d144 !important;
  color: rgba(255, 255, 255, 0.85);
}
.badge-dark {
  background-color: #343a40 !important;
}
.badge-pill {
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
}
.main-container {
  position: relative;
  min-height: 100vh;
  width: 100%;
}
.tab-content .tab-pane .d-flex .btn {
  height: 34px;
  line-height: 15px;
  font-size: 13px;
}
.card-notification .card-container {
  max-height: 350px;
  overflow-y: auto;
}
.card-notification .actions .dropdown-menu {
  top: 25% !important;
  min-width: 200px !important;
  min-height: auto !important;
  width: 200px !important;
}
.card-notification .actions .btn-group .dropdown-toggle.btn-primary i {
  font-size: 22px !important;
}
/* ********************************************************************************************
    Button
*********************************************************************************************** */

button,
.btn {
  outline: none !important;
  font-weight: bold;
  border-radius: 0px;
}
.btn,
a:link.btn {
  height: 34px;
  padding: 6px 15px;
}
.btn-primary,
a.btn-primary,
a:visited.btn-primary a:link.btn-primary {
  background-color: var(--body-color);
  /* var(--body-color); */
  border: 1px solid var(--body-color);
  /* var(--body-color); */
  color: rgba(255, 255, 255, 0.9);
}
.btn-primary:hover,
a.btn-primary:hover,
a:visited.btn-primary a:link.btn-primary:hover {
  background-color: var(--body-color) !important;
  border: 1px solid var(--body-color) !important;
  box-shadow: 0 3px 6px rgba(100, 52, 137, 0.3) !important;
  color: #fff;
}
.btn-primary:focus,
a.btn-primary:focus,
a:visited.btn-primary a:link.btn-primary:focus {
  background-color: var(--body-color);
  border: 1px solid var(--body-color);
  color: #fff !important;
  box-shadow: none !important;
}
.btn-primary:active,
a.btn-primary:active,
a:visited.btn-primary a:link.btn-primary:active {
  background-color: var(--body-color) !important;
  border: 1px solid var(--body-color) !important;
  color: #fff !important;
  box-shadow: none !important;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--body-color);
  border: 1px solid var(--body-color);
  color: rgba(255, 255, 255, 0.9);
}
.btn-info,
a:link.btn-info {
  color: rgba(255, 255, 255, 0.85) !important;
}
.btn-info.btn-outline,
a:link.btn-info.btn-outline {
  color: #399af2 !important;
}
.btn.btn-sm,
a:link.btn.btn-sm {
  padding: 0.5rem 0.75rem;
  height: auto;
}
.btn-icon-only {
  width: 30px !important;
  height: 25px !important;
  text-align: center !important;
  padding: 0px !important;
}
.btn-icon-only i {
  margin: 0 !important;
  padding: 0;
}
.btn-secondary:hover i,
.btn-secondary:focus i {
  color: #fff;
}
.dropdown-toggle.btn-secondary.btn-primary {
  background-color: var(--body-color);
  border: 1px solid #d8e3ef;
  color: #788db4 !important;
}
.dropdown-toggle.btn-secondary.btn-primary:hover,
a:link.dropdown-toggle.btn-secondary.btn-primary:hover {
  background-color: #6e85af !important;
  border: 1px solid #6e85af !important;
  box-shadow: 0 5px 10px rgba(120, 141, 180, 0.4) !important;
  color: #fff !important;
}
.dropdown-toggle.btn-secondary.btn-primary:not(:disabled):not(.disabled).active,
.dropdown-toggle.btn-secondary.btn-primary:not(:disabled):not(.disabled):active,
.show > .dropdown-toggle.btn-secondary.btn-primary {
  background-color: #fff !important;
  border: 1px solid #d8e3ef !important;
  color: #788db4 !important;
}
.dropdown-toggle.btn-secondary.btn-primary:focus i {
  color: #788db4;
}
.dropdown-toggle.btn-secondary.btn-primary::after {
  display: none;
}
.dropdown-toggle.btn-secondary.btn-primary i {
  margin-left: 0px;
}
.dropdown .fa-sliders {
  margin-top: -5px;
}
.btn-primary2 {
  background-color: #6e85af !important;
  border-color: #6e85af;
  color: #fff;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.button-with-textbox,
.page-header .btn,
.page-header a:link.btn,
.removebtn {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 35px;
}
.btn-secondary,
a:link.btn-secondary,
.btn-secondary:hover,
a:link.btn-secondary:hover,
.btn-secondary:focus,
a:link.btn-secondary:focus {
  background-color: #fff !important;
  border: none !important;
  color: #333333 !important;
  box-shadow: 1.7px 1.7px 5px rgb(0 0 0 / 9%) !important;
}
.btn-link {
  font-weight: normal;
  color: var(--body-color);
}
.btn-link:hover {
  color: var(--body-color);
  text-decoration: underline;
}

/* ********************************************************************************************
    Navbar & Sidebar
*********************************************************************************************** */

.site-logo li .logo {
  top: 15px;
  left: 20px;
}

.site-logo li .brand-text {
  padding: 20px 0 0 32px;
}

.logo-custom {
  margin-top: 15px;
  margin-left: 1.25rem;
}

.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a,
body .sidebar.sidebar-left .sidebar-content .main-menu .sidebar-header {
  font-size: 1rem;
  font-weight: bold;
  color: #fff !important;
}
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a {
  padding: 12px 15px 12px 20px;
}

body .top-toolbar.navbar-desktop {
  background: var(--body-color);
}
body .aside-toolbar,
body .top-toolbar.navbar-mobile.navbar-tablet {
  background-color: var(--body-color);
}
body .top-toolbar.navbar-desktop .navbar-nav .nav-item > a,
body .top-toolbar.navbar-desktop .navbar-nav .nav-item > a i {
  color: rgba(255, 255, 255, 0.95);
}
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
li
a:hover,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
li
a:hover
i {
  color: var(--body-color) !important;
}
.mCSB_inside > .mCSB_container {
  margin-right: 15px;
}
.top-toolbar .dropdown .dropdown-menu {
  top: 100%;
}
.top-toolbar .card.card-notification .card-header {
  min-height: 50px;
  height: 50px;
}
.top-toolbar .card.card-notification .card-header .actions.top-right {
  top: 10px;
  right: 10px;
}
body .top-toolbar {
  /* position: fixed;
    right: 0;
    left: 0; */
}
body .sidebar-left {
  /* background: var(--body-color) !important; */
  /* #9b2630; */
  /* rgb(100,52,137); */
  /* background-image: linear-gradient(to bottom, red,orange); */
  background: linear-gradient(0deg, var(--body-color) 0%,var(--body-color) 100%);
  /* background:linear-gradient(to right, rgba(255, 255, 255, 0),#f2397d); */
}
body .sidebar ~ .content-wrapper {
  /* padding-top: 65px;
    padding-left: 255px; */
}
body #app .content {
  /* padding-top: 65px; */
}
.sidebar.sidebar-left .sidebar-content .main-menu {
  /* padding-bottom: 10px; */
}
.sidebar.sidebar-left ~ .content-wrapper {
  /* padding-top: 0px; */
}
.mobile-topbar-toggle ~ .top-toolbar.navbar-desktop {
  /* top: 65px; */
}
body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .open > a,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.metismenu
.open
> a:focus,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.metismenu
.open
> a:hover,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li.active
> a,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li.active
> a
> i,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li.nav-dropdown.active
> ul
> li.nav-dropdown.active
> ul
> li.active
> a,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li.nav-dropdown
> ul
> li.nav-dropdown
> ul
> li
> a:hover,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li
> a:active,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li
> a:hover {
  color: var(--body-color);
}
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li.active
> a,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li.active
> a:focus,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li.active
> a:hover {
  border-left: solid 0px var(--body-color);
}
/* body .sidebar-left, body .sidebar-left .sidebar-content {
    background-color: var(--body-color);
} */
body .sidebar-left .sidebar-content {
  background-color: transparent;
}
.site-logo a {
  color: #fff;
  font-size: 25px;
  font-weight: bold;
}
.main-menu .nav li .icons {
  width: 40px;
  margin-right: 15px;
  text-align: right;
}

.main-menu .nav li.sidebar-header .icons {
  width: 20px;
  margin-right: 15px;
  text-align: left;
}
.main-menu .nav li svg {
  vertical-align: middle;
  stroke: #fff;
}
.main-menu .nav li a:hover svg,
.main-menu .nav li button:hover svg {
  stroke: var(--body-color) !important;
}
.main-menu .nav li.sidebar-header svg {
  stroke: inherit;
}
/* .main-menu .nav li a svg{
    width: 50px;
    height: 30px;
    stroke: #fff;
} */
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
li
a:hover,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
li.active
a,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li.active
> a:hover {
  background: #f2f6f5 !important;
  color: var(--body-color) !important;
}
/* .main-menu .nav li a:hover svg,
.main-menu .nav li.active a svg{
    stroke: var(--body-color);
} */
.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a:focus,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.metismenu
> li
> a:active {
  color: var(--body-color) !important;
}
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li
> a:focus
.icons
svg,
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li
> a:active
.icons
svg {
  stroke: var(--body-color);
}
body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .open > a,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.metismenu
.open
> a:focus,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.metismenu
.open
> a:hover,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li.active
> a,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li.active
> a
> i,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li.nav-dropdown.active
> ul
> li.nav-dropdown.active
> ul
> li.active
> a,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li.nav-dropdown
> ul
> li.nav-dropdown
> ul
> li
> a:hover,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li
> a:active,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.nav.metismenu
> li
> a:hover,
body
.sidebar.sidebar-left
.sidebar-content
.main-menu
.metismenu
> li
> a:focus {
  background-color: #f2f6f5;
}
.power-icon,
.search-icon svg,
.search-icon path,
.search-icon line {
  stroke: #fff !important;
}
.top-toolbar .navbar-nav .nav-item:not(.dropdown) {
  min-width: 2.5rem;
}
.top-toolbar .navbar-nav .nav-item:not(.dropdown) a {
  min-width: 2.7rem;
}
.top-toolbar .navbar-nav .nav-item:not(.dropdown) svg {
  height: 18px;
  width: 18px;
}
.top-toolbar .navbar-nav .nav-item.search-container .close-icon svg {
  height: 15px;
  width: 15px;
}


/* ********************************************************************************************
    Table
*********************************************************************************************** */
.table {
  font-size: 13px;
  margin-bottom: 0.125rem;
}
.table-hover tbody tr:hover {
  background-color: rgb(100 52 137 / 5%);
}

.table tbody th {
  font-weight: 500;
}
.table td,
.table th {
  /* padding: 0.55rem 0.75rem; */
  border-top: none;
}
.table td,
.table th,
.table thead th {
  vertical-align: middle;
}
.table thead th {
  font-weight: bold;
  color: #333333;
}

.table-field-status .dropdown .dropdown-menu .dropdown-item i {
  vertical-align: inherit;
  font-size: 11px;
  margin-right: 5px;
}

.table-field-actions,
.table-field-status {
  width: 120px !important;
  text-align: center;
}
.table-field-actions .dropdown-menu,
.table-field-status .dropdown-menu {
  width: 150px;
}
.table-field-actions .dropdown .dropdown-menu .dropdown-item {
  font-size: 0.75rem;
  padding: 10px 15px;
}

.table tr td:first-child,
.table tr th:first-child {
  padding-left: 1.25rem;
}

.remove-padding.table td:first-child,
.remove-padding.table th:first-child {
  padding-left: 0 !important;
}
.remove-padding.table td:last-child,
.remove-padding.table th:last-child {
  padding-right: 0 !important;
}

.table-small-padding.table > tbody > tr > th,
.table-small-padding.table > tbody > tr > td {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 13px;
  font-weight: normal;
}

.table > thead > tr > th > a {
  position: relative;
  color: #617182;
}
.table > thead > tr > th > a:before,
.table > thead > tr > th > a:after {
  position: absolute;
  display: block;
  opacity: 0.5;
  bottom: -0.15em;
  margin-right: -25px;
}
.table > thead > tr > th > a:before {
  content: "\2193";
  right: 0em;
}
.table > thead > tr > th > a:after {
  content: "\2191";
  right: 0.5em;
}
.table > thead > tr > th.descending > a:before,
.table > thead > tr > th.ascending > a:after {
  opacity: 1;
}
.table > thead > tr > th button {
  background: transparent;
  border: 0;
  color: #5accca;
  width: 100%;
  text-align: left;
  cursor: pointer;
}
.table > thead > tr > th button .caret {
  float: right;
}
.table > thead > tr > th button .caret:before {
  font: normal normal normal 14px/1 LineAwesome;
  display: block;
  font-size: 18px;
  margin-top: 2px;
  width: 100%;
  color: #718eb1;
  float: right;
}
.table > thead > tr > th button.desc .caret:before {
  content: "\f308";
}
.table > thead > tr > th button.asc .caret:before {
  content: "\f307";
}
.table > thead > tr > th button:focus {
  outline: none;
}
.table .sorting span {
  position: relative;
  cursor: pointer;
}
.table .sorting span::before,
.table .sorting span::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  right: -0.75em;
  content: "\2191";
  margin-top: 0px;
}
.table .sorting span::after {
  right: -1.25em;
  content: "\2193";
}

.pagination a:link,
.pagination a:visited {
  color: var(--body-color);
}
.pagination .page-item .page-link {
  height: 24px !important;
  min-width: 28px !important;
  white-space: nowrap !important;
  text-align: center !important;
  width: auto !important;
}
.pagination-sm .page-link{
  padding: 0.25rem 0.3rem;
}
.pagination .page-item.active .page-link {
  background-color: var(--body-color);
  border-color: var(--body-color);
  text-align: center;
  padding: 0;
  border-radius: 0;
}
.pagination .page-item.disabled .page-link {
  line-height: 1;
}
.accordion-table {
  margin: 0 -10px;
  width: auto;
}
.accordion-table .table {
  border-collapse: separate;
  border-spacing: 0px 10px;
}
.table tbody tr {
  box-shadow: 1px 1px 5px rgb(0 0 0 / 5%);
}
select {
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="10.073" height="5.938" viewBox="0 0 10.073 5.938"><g id="Group_3217" data-name="Group 3217" transform="translate(-645.673 -1766.673)"><g id="Group_3216" data-name="Group 3216"><path id="Path_1806" data-name="Path 1806" d="M2032.87,4070.124l-4.586,4.586-4.586-4.586" transform="translate(-1377.575 -2303)" fill="" stroke="#000" stroke-miterlimit="10" stroke-width="1.275"/></g></g></svg>');
  background-repeat: no-repeat;
  background-position: right center;
  background-color: var(--body-color) !important;
  -webkit-mask: url("../img/arrow-down.svg") no-repeat right;
  mask: url("../img/arrow-down.svg") no-repeat right; */
}
.table-top-select select {
  border: 0;
  font-weight: 600;
  color: var(--body-color);
}
.table-top-select select:focus {
  border: 0;
  color: var(--body-color);
}
.arrow-down {
  position: relative;
}
.arrow-down .arrow-down-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
}
.arrow-down.form-group .arrow-down-icon {
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 15px;
}
.table-top-search,
.date-picker {
  position: relative;
  max-width: 250px;
}
.table-top-search .icon,
.date-picker .icon {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 15px;
}
.date-picker .icon {
  top: 4px;
}
.table-top-search .form-control,
.date-picker .form-control {
  border: 1px solid rgba(112, 112, 112, 0.3) !important;
  /* box-shadow: 1px 1px 5px rgb(0 0 0 / 25%); */
  border-radius: 0 !important;
  height: 28px;
  padding-left: 35px !important;
}
.table-top-search .form-control:focus,
.date-picker .form-control:focus {
  outline: none !important;
  border: 1px solid var(--body-color) !important;
}
.refresh-icon {
  cursor: pointer;
}
.table-top-search input::-webkit-input-placeholder,
.table-top-select .date-picker input::-webkit-input-placeholder   {
  /* Chrome/Opera/Safari */
  color: #333333 !important;
}
.table-top-search input::-moz-placeholder,
.table-top-select .date-picker input::-webkit-input-placeholder {
  /* Firefox 19+ */
  color: #333333 !important;
}
.table-top-search input:-ms-input-placeholder,
.table-top-select .date-picker input::-webkit-input-placeholder {
  /* IE 10+ */
  color: #333333 !important;
}
.table-top-search input:-moz-placeholder,
.table-top-select .date-picker input::-webkit-input-placeholder {
  /* Firefox 18- */
  color: #333333 !important;
}
.common-details input::-webkit-input-placeholder,
.common-details textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #666666 !important;
  font-weight: bold;
}
.common-details input::-moz-placeholder,
.common-details textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #666666 !important;
  font-weight: bold;
}
.common-details input:-ms-input-placeholder,
.common-details textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #666666 !important;
  font-weight: bold;
}
.common-details input:-moz-placeholder,
.common-details textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #666666 !important;
  font-weight: bold;
}
.table-top-search svg,
.table-top-search svg path,
.table-top-search svg line {
  stroke: #333333;
}
.collapse-icon,
.actions-dropdown {
  opacity: 1;
}
/* .table tr:hover .collapse-icon, */
/* .table tr:hover .actions-dropdown {
  opacity: 1;
} */
/* ********************************************************************************************
    Dropdown
*********************************************************************************************** */

.dropdown-notifications a {
  position: relative;
}
.dropdown-notifications a i.circle-dot {
  position: absolute !important;
  right: 18px !important;
  top: 8px !important;
  font-size: 10px !important;
  color: #ef606d !important;
}
.dropdown.filters .dropdown-menu,
.dropdown300 {
  width: 300px !important;
}
.dropdown-menu #filter-form .fa-times {
  font-size: 14px;
  margin-top: -3px;
}
.dropdown-menu #filter-form .btn-link {
  padding-left: 0 !important;
  line-height: 20px;
}
.dropdown .dropdown-menu {
  z-index: 1000;
}
.dropdown .dropdown-menu,
.dropleft .dropdown-menu,
.dropright .dropdown-menu,
.dropup .dropdown-menu,
.input-group .dropdown-menu {
  box-shadow: 2px 2px 7px rgb(0 0 0 / 10%);
  border-radius: 0;
}
.dropdown .dropdown-menu .dropdown-item.active,
.dropdown .dropdown-menu .dropdown-item:active,
.dropdown .dropdown-menu .dropdown-item:focus,
.dropdown .dropdown-menu .dropdown-item:hover,
.dropleft .dropdown-menu .dropdown-item.active,
.dropleft .dropdown-menu .dropdown-item:active,
.dropleft .dropdown-menu .dropdown-item:focus,
.dropleft .dropdown-menu .dropdown-item:hover,
.dropright .dropdown-menu .dropdown-item.active,
.dropright .dropdown-menu .dropdown-item:active,
.dropright .dropdown-menu .dropdown-item:focus,
.dropright .dropdown-menu .dropdown-item:hover,
.dropup .dropdown-menu .dropdown-item.active,
.dropup .dropdown-menu .dropdown-item:active,
.dropup .dropdown-menu .dropdown-item:focus,
.dropup .dropdown-menu .dropdown-item:hover {
  color: var(--body-color) !important;
}
.dropdown .dropdown-menu .dropdown-item.active i,
.dropdown .dropdown-menu .dropdown-item:active i,
.dropdown .dropdown-menu .dropdown-item:focus i,
.dropdown .dropdown-menu .dropdown-item:hover i,
.dropleft .dropdown-menu .dropdown-item.active i,
.dropleft .dropdown-menu .dropdown-item:active i,
.dropleft .dropdown-menu .dropdown-item:focus i,
.dropleft .dropdown-menu .dropdown-item:hover i,
.dropright .dropdown-menu .dropdown-item.active i,
.dropright .dropdown-menu .dropdown-item:active i,
.dropright .dropdown-menu .dropdown-item:focus i,
.dropright .dropdown-menu .dropdown-item:hover i,
.dropup .dropdown-menu .dropdown-item.active i,
.dropup .dropdown-menu .dropdown-item:active i,
.dropup .dropdown-menu .dropdown-item:focus i,
.dropup .dropdown-menu .dropdown-item:hover i {
  color: var(--body-color);
}
.dropdown .dropdown-menu .dropdown-item,
.dropleft .dropdown-menu .dropdown-item,
.dropright .dropdown-menu .dropdown-item,
.dropup .dropdown-menu .dropdown-item {
  color: #666666;
}
.dropdown-toggle::after {
  position: relative;
  top: 2px;
  left: 2px;
}
.btn-group .dropdown-toggle::after {
  display: none;
}
/* .btn-group .dropdown-toggle.btn-primary {
    background-color: transparent !important;
    border: none !important;
} */
.btn-group .dropdown-toggle.btn-primary:hover {
  box-shadow: none !important;
}
.btn-group .dropdown-toggle.btn-primary i {
  font-size: 24px !important;
  color: #fff !important;
}

.btn-group .dropdown-menu {
  top: auto !important;
  animation-name: none;
}
.btn-group .dropdown-menu .dropdown-item {
  color: #666666;
}
.btn-group .dropdown-item i {
  margin-right: 0.25rem;
  vertical-align: -1px !important;
}
.btn-group .dropdown-item.active,
.btn-group .dropdown-item:active,
.btn-group .dropdown-item:hover {
  color: var(--body-color);
  text-decoration: none;
  background-color: rgba(231, 241, 253, 0.2);
}
body .top-toolbar.navbar-desktop .dropdown-menu ul li:hover a {
  color: var(--body-color);
}
/* ********************************************************************************************
    Tabs
*********************************************************************************************** */
.nav .nav-item .nav-link.active,
.nav .nav-item .nav-link:active,
.nav .nav-item .nav-link:hover,
.nav-tabs.primary-tabs .nav-item .nav-link.active.show,
.nav-tabs.primary-tabs .nav-item .nav-link:hover,
.nav-tabs.primary-tabs .nav-item.show .nav-link {
  color: var(--body-color);
}
.nav-tabs.primary-tabs .nav-item .nav-link.active {
  border-bottom: solid 1px var(--body-color);
}
.nav-tabs .nav-item {
  margin-bottom: 0;
}
.navbar-nav li.active a,
.main-menu li.active a {
  color: var(--body-color) !important;
}
/* ********************************************************************************************
    Form
*********************************************************************************************** */

.left-form-content {
  margin-right: 40px;
  margin-left: 20px;
}
.fileinput .thumbnail > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.upload-img-label {
  font-size: 11px;
  margin-bottom: 0;
  font-weight: normal;
}
.uploaded-user-image .upload-icon {
  position: absolute;
  bottom: 15px;
  left: 10px;
}

.label {
  font-weight: 600;
}
label em,
.control-label em {
  font-style: normal;
  color: #ff0026;
  font-weight: normal;
  font-family: arial;
}

.form-row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.form-control:focus {
  border: 1px solid var(--body-color);
}
.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  height: 35px;
}
.additional-details .form-control,
.common-details .form-control {
  border: 1px solid var(--body-color);
  border-radius: 0;
}
.common-details .form-control {
  font-size: 13px;
  font-weight: bold;
  color: #000;
  padding: 0.516rem 1rem;
}
select.form-control {
  padding: .375rem 1.5rem .375rem .75rem !important;
}

/* Select2 */
.input-group
.select2-container--default:first-of-type
.select2-selection--single {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.select2-container--default
.select2-selection--multiple
.select2-selection__choice__remove {
  font-family: Arial, Helvetica, sans-serif;
}
.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  min-height: 35px;
}
.select2-container--default
.select2-selection--single
.select2-selection__rendered {
  /*padding: 0.49rem 1rem;*/
  color: #323a48;
  position: relative;
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  font-size: 0.875rem;
  font-weight: normal;
}
.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
  border: 1px solid #736cc7;
  box-shadow: none;
}
select.form-control:not([size]):not([multiple]) {
  height: calc(2rem + 2px);
}
.common-details select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
  padding: .375rem 1.5rem .375rem .75rem !important;
}
/* Checkbox & Radio */
.custom-control-label {
  cursor: pointer;
}
.control.control-outline {
  cursor: pointer;
}
.control.control-outline .control__indicator {
  width: 16px;
  height: 16px;
  top: -7px;
  border: 0.4px solid var(--body-color);
  border-radius: 0;
}
.control.control-outline.control-primary input:checked ~ .control__indicator {
  background-color: var(--body-color);
}
.control.control-outline.control-primary input:checked ~ .control__indicator {
  border: 0.4px solid var(--body-color);
}
.control.control-outline.control-primary.control--checkbox
.control__indicator:after {
  display: none;
}
.control.control-outline input:checked:focus ~ .control__indicator,
.control.control-outline:hover
input:not([disabled]):checked
~ .control__indicator {
  background: var(--body-color);
}
.bootstrap-timepicker-widget table td input {
  border-width: 1px;
}
.notifications .control.control-outline .control__indicator,
.accessories .control.control-outline .control__indicator {
  top: 50%;
  transform: translateY(-50%);
}
.login-box-footer .control.control-outline .control__indicator {
  top: 2px;
}

/* ********************************************************************************************
    Login
*********************************************************************************************** */

.sign-in-form {
  margin: 10% auto;
}
.login-box,
.register-box {
  width: 100%;
  max-width: 410px;
  box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07),
  0 2px 9px 0 rgba(18, 106, 211, 0.06);
  border-radius: 10px;
  margin: 3% 4%;
}
.register-box {
  max-width: 580px;
}
.login-box.custom-login-box {
  max-width: 610px !important;
}
.login-logo,
.register-logo {
  background: #fff;
  margin-bottom: 0;
  padding-top: 20px;
  text-align: center;
}
.login-logo img {
  height: 70px;
}
.login-box-msg,
.register-box-msg {
  font-weight: normal;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 30px;
  text-align: center;
}
.login-box-body,
.register-box-body {
  color: inherit;
  padding: 20px 25px;
  background: #fff;
}
.login-box-body .form-control {
  background: #fff !important;
  border-color: #dfe7f3;
  height: 44px;
  text-align: center;
}
.login-box-body .form-control:focus {
  background-color: #fff;
  border-color: var(--body-color);
}
.login-box-body .btn {
  font-size: 15px;
  padding-top: 22px !important;
  padding-bottom: 22px !important;
  margin-top: 5px;
}
.login-box-footer {
  background-color: #fff;
  padding: 0px 25px 20px;
  font-size: 14px;
}
.login-box-body textarea.form-control {
  border-radius: 7px !important;
  height: inherit !important;
}
.register-box .form-control {
  text-align: left;
  height: 40px;
}
/* **************************************************************************************
    Modal
*************************************************************************************** */

.modal-dialog {
  height: 100%;
  margin: 0px auto;
  padding: 60px 0px;
}
.modal-dialog > form {
  height: 100%;
}
.modal-content {
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal-header,
.modal-body,
.modal-footer {
  padding: 18px 30px;
}
.modal-header {
  background: var(--body-color) url("../img/warning-bg.svg") no-repeat center center;
  background-size: cover;
}
.modal-title {
  font-size: 1.6rem !important;
  color: #fff !important;
}
.modal-header,
.modal-footer {
  border: none;
  border-radius: 0;
}
.modal-header .close {
  padding: 0;
  margin: 0;
  opacity: 1;
}
.modal-body {
  overflow-y: auto;
}

/* ********************************************************************************************
    Chart
*********************************************************************************************** */

.pie-chart-legend .table {
  font-size: 16px;
  margin: 0px auto;
  max-width: 340px;
  white-space: nowrap;
}
.pie-chart-legend .table .colorbox,
.chart-legend ul > li > span {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  vertical-align: 0px;
  margin-right: 10px;
}
.pie-chart-legend .table .value {
  font-weight: 600;
  color: #525e70;
}
.chart-legend ul {
  list-style: none;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  float: right;
}
.chart-legend ul li {
  display: inline-block;
  margin-left: 20px;
}
.chart-legend ul > li > span {
  margin-right: 10px;
}
/* ********************************************************************************************
    Map
*********************************************************************************************** */

.map-sidebar,
.filter-sidebar {
  width: 320px;
  min-width: 320px;
  height: calc(100vh - 65px);
  display: none;
  transition: all 0.3s;
}
.map-sidebar {
  height: calc(100vh - 140px);
}
.map-sidebar .card-body {
  height: calc(100vh - 196px);
}
.filter-sidebar .card-body {
  height: calc(100vh - 191px);
}
.map-list li {
  padding: 1rem;
  border-left: 3px solid transparent !important;
  border-bottom: 1px solid #eaf3fd !important;
}
.map-list li.active {
  border-left: 3px solid #fab854 !important;
  background-color: rgba(250, 184, 84, 0.11);
}
.map-sidebar-btn,
.filter-sidebar-btn {
  position: fixed;
  top: 140px;
  left: 0px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #ee3124;
  z-index: 9;
  display: block;
  transition: all 0.3s;
}
.filter-sidebar-btn {
  left: auto;
  right: 0px;
  background-color: #fff;
}
.map-sidebar-btn.active,
.filter-sidebar-btn.active {
  display: none;
}
.map-sidebar-btn.active ~ .map-sidebar,
.filter-sidebar-btn.active ~ .filter-sidebar {
  display: block;
}
.filter-sidebar .DateRangePicker {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}
.filter-sidebar .DateRangePickerInput {
  width: 100%;
}
.SingleDatePicker {
  flex: 1 1 auto;
}
.SingleDatePicker .SingleDatePickerInput {
  display: block;
}
.SingleDatePicker .DateInput {
  width: 100% !important;
}
.SingleDatePicker .DateInput_input__focused {
  border-bottom: none !important;
}
.driver-view-map-image {
  height: 400px;
}

/* ********************************************************************************************
    Date Range Picker
*********************************************************************************************** */

.rmdp-shadow {
  box-shadow: 0 0 5px #d4d4d4 !important;
}
.rmdp-ep-shadow:after {
  box-shadow: 0 0 5px #d4d4d4 !important;
}
.rmdp-week-day {
  color: var(--body-color) !important;
}
.rmdp-range {
  background-color: var(--body-color) !important;
  box-shadow: 0 0 3px #d4d4d4 !important;
}
.rmdp-range-picker-footer h6 {
  font-size: 13px !important;
}
.rmdp-button {
  background-color: var(--body-color) !important;
  font-size: 12px !important;
}
.rmdp-arrow-container:hover {
  background-color: var(--body-color) !important;
  box-shadow: 0 0 3px #d4d4d4 !important;
}
.rmdp-arrow {
  border-color: var(--body-color) !important;
  margin-top: 7px !important;
}
.rmdp-arrow-container:hover .rmdp-arrow {
  border-color: #fff !important;
}
.rmdp-arrow i {
  margin-left: 2px !important;
}
.rmdp-range-picker-footer span {
  font-size: 14px !important;
}
.rmdp-cancel {
  line-height: 18px !important;
}
.rmdp-day span {
  font-size: 12px !important;
}
.rmdp-day, .rmdp-week-day {
  height: 30px !important;
  width: 30px !important;
}
.rmdp-day.rmdp-today span {
  background-color: #00a0ed !important;
}

/* ********************************************************************************************
    Autocomplete
*********************************************************************************************** */

.autocomplete-suggestions {
  border: 1px solid rgba(120, 141, 180, 0.3);
  background: #fff;
  cursor: default;
  overflow: auto;
}
.autocomplete-suggestion {
  padding: 3px 10px;
}
.autocomplete-suggestion:hover {
  background: #5accca;
  cursor: pointer;
  color: #fff;
}

/* ********************************************************************************************
    Accordion
*********************************************************************************************** */

.accordion > .card > .card-header .btn-link {
  padding: 6px 125px 6px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.accordion > .card > .card-header .btn-link:after {
  right: 8.5%;
  top: 50%;
}
.assign-timeslot.accordion > .card > .card-header .btn-link {
  padding: 6px 20px 6px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.assign-timeslot.accordion > .card > .card-header .btn-link:after {
  right: 2.5%;
  top: 50%;
}
.accordian-body .card {
  border: 0.5px solid var(--body-color) !important;
}
.accordian-body .card-header {
  height: 40px !important;
  min-height: 35px !important;
}
.accordion-icon {
  height: 60px;
  width: 60px;
  stroke: #fff;
}
.accordion .card-header {
  border-radius: 0;
}
/* ********************************************************************************************
    Date Picker
*********************************************************************************************** */
.DateInput {
  width: 90px !important;
}
.DateInput_input {
  font-size: 12px !important;
  padding: 0.375rem 0.75rem !important;
  height: 33px;
}
.DateInput_input__focused {
  border-bottom: 2px solid var(--body-color) !important;
}
.DateRangePickerInput__withBorder {
  border: 1px solid #dfe7f3 !important;
  border-radius: 0.25rem !important;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: var(--body-color) !important;
  border: 1px double var(--body-color) !important;
}
.CalendarDay__selected_span {
  background: var(--body-color) 80 !important;
  border: 1px double var(--body-color) 80 !important;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: var(--body-color) 80 !important;
  border: 1px double var(--body-color) 80 !important;
  color: #484848 !important;
}
.DateRangePickerInput_arrow_svg {
  height: 19px !important;
  width: 19px !important;
  margin-top: -3px;
  margin-left: -3px;
}
/* ********************************************************************************************
    CK editor
*********************************************************************************************** */
.ck-content {
  min-height: 250px;
}
.ck.ck-toolbar {
  border: 1px solid #dfe7f3 !important;
  border-bottom: none !important;
}
.ck.ck-content.ck-editor__editable {
  border-color: #dfe7f3 !important;
  box-shadow: none !important;
}
.ck.ck-content.ck-editor__editable:focus {
  border-color: var(--body-color) !important;
}
.ck.ck-list__item .ck-button.ck-on {
  background: var(--body-color) !important;
}
.ck.ck-list__item .ck-button:active {
  border: none !important;
}
.ck.ck-dropdown__panel {
  border: 1px solid #dfe7f3 !important;
  box-shadow: none !important;
}
.ck.ck-dropdown .ck-dropdown__arrow {
  z-index: 0 !important;
}

/* ********************************************************************************************
    Table Thead Fix
*********************************************************************************************** */
.table-thead-fix {
  max-height: 450px;
}
.table-thead-fix thead th {
  position: sticky;
  top: 0;
  font-weight: 600;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #ffffff;
  z-index: 1;
  border-bottom: none !important;
}
.table-thead-fix thead th::before,
.table-thead-fix thead th::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}
.table-thead-fix thead th::after {
  bottom: 0px;
  border-bottom: 1px solid rgba(234, 243, 253, 0.9);
}
.table-thead-fix tbody tr:first-child td {
  border-top: none !important;
}

/* ********************************************************************************************
    Others
*********************************************************************************************** */

.inner-filter select.form-control:not([size]):not([multiple]) {
  height: calc(2.0625rem + 2px);
}
.inner-filter .btn {
  height: 35px;
  line-height: 9px;
}
.cms-page p {
  font-size: 15px;
}
.cms-page ul li,
.cms-page ol li {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}
.text-underline {
  text-decoration: underline !important;
}
.customer-list li .customer-name {
  margin-left: 15px;
}
.modal-dialog-scrollable .modal-body {
  max-height: calc(100vh - 200px);
}
i.v-align-1 {
  vertical-align: 1px !important;
}
.top-1 {
  position: relative;
  top: 1px !important;
}
.border-bottom-none .table tbody tr:last-child td {
  border-bottom: none;
}
#filter-form label {
  font-size: 13px !important;
}
.form-control-plaintext {
  pointer-events: none;
}
.no-arrow.dropdown-toggle::after {
  display: none;
}
.date-time {
  font-size: 12px;
  color: #ffce67;
  margin-right: 15px;
  line-height: 67px;
}
.inner-page-date .DateRangePicker {
  flex: 1 1 auto !important;
}
.inner-page-date .DateRangePickerInput,
.inner-page-date.schedule .DateRangePicker {
  width: 100% !important;
}
.inner-page-date.schedule .DateInput {
  width: 80px !important;
}
.SingleDatePickerInput__withBorder {
  border: 1px solid #dfe7f3 !important;
}
.actions.top-right.inner-tab {
  top: 78px;
}
.actions.top-right.inner-tab.minus-tab {
  top: 74px;
}
.noti-count {
  position: absolute;
  top: 16px;
  right: 18px;
  font-size: 11px;
  background-color: var(--body-color);
  height: 16px;
  width: 16px;
  line-height: 16px;
  color: #fff;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}
.download-btn {
  position: absolute;
  top: 0;
  right: 0;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  height: 6px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

/* ********************************************************************************************
    React Select Dropdown
*********************************************************************************************** */
.custom-select-dropdown span + span + div {
  border: none !important;
  height: inherit;
  min-height: 35px;
  max-height: auto;
  box-shadow: none;
  font-size: 14px;
  font-weight: bold;
}
.custom-select-dropdown span + span + div:hover {
  border-color: var(--body-color);
}
.custom-select-dropdown span + span + div div span {
  display: none;
}
.custom-select-dropdown span + span + div div span + div {
  height: 15px;
  width: 15px;
  padding: 0;
  margin-right: 10px;
  margin-top: -8px;
}
.custom-select-dropdown span + span + div div span + div svg {
  color: var(--body-color);
}
.custom-select-dropdown span + span + div > div > div {
  font-size: 0.875rem;
  margin-top: -1px;
  margin-left: 5px;
  color: var(--body-color);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.custom-select-dropdown span + span + div + div {
  font-size: 0.875rem;
  border: 1px solid #dfe7f3;
  box-shadow: 0 4px 14px rgba(174, 197, 231, 0.50);
}
.custom-select-dropdown span + span + div input {
  height: 20px;
  color: var(--body-color) !important;
}
.react-select-dropdown__option.react-select-dropdown__option--is-selected {
  background-color: var(--body-color) !important;
}
.react-select-dropdown__menu {
  margin: 0 !important;
  min-width: 125px !important;
  box-shadow: 2px 2px 7px rgb(0 0 0 / 10%) !important;
}
.react-select-dropdown__option:hover {
  background-color: rgba(231,241,253,.2);
}

/* ********************************************************************************************
    New Design
*********************************************************************************************** */

.breadcrumb {
  margin-bottom: 0;
  background-color: #fff;
  box-shadow: 1.3px 1.3px 4.54px 0px rgb(0 0 0 / 10%);
  border-radius: 0;
  align-items: center;
  padding: 0.5rem 1.25rem;
}
.breadcrumb-item {
  font-size: 1rem;
  font-weight: bold;
}
.breadcrumb-item a,
.breadcrumb-item.active {
  color: var(--body-color);
}
.breadcrumb-item a:hover {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: "-";
  color: var(--body-color);
  display: none;
}

.card {
  border-radius: 0px;
  box-shadow: 2px 2px 7px rgb(0 0 0 / 10%);
  margin-bottom: 1rem;
}
.card .card-header {
  padding: 1rem;
}
.card-body {
  padding: 1rem;
}
.card-footer {
  padding: 1rem;
}

.primary-stroke {
  stroke: var(--body-color);
}
.cus-line{
  padding-left: 15px;
  display: flex;
}
.Active{
  background-color: rgb(243, 224, 224) !important;
}
.white-stroke {
  stroke: #fff;
}

.dashboard-filter {
  margin: 0 -5px;
}
.dashboard-filter .btn {
  box-shadow: 1.79px 1.79px 6.26px 0px rgb(0 0 0 / 10%);
  height: auto;
  margin-right: 10px;
  min-width: 120px;
  padding: 5px;
  color: var(--body-color);
  font-size: 13px !important;
  margin: 5px !important;
}
.dashboard-filter .btn .icon {
  display: block;
  min-height: 50px;
  line-height: 50px;
  margin-bottom: 0.5rem;
}
.dashboard-filter .btn .icon svg {
  max-height: 40px;
  max-width: 90px;
  stroke: var(--body-color);
  fill: var(--body-color);
  vertical-align: middle;
}
.dashboard-filter .btn.active .icon svg {
  stroke: #fff;
}
.dashboard-filter .btn.active {
  color: #fff;
  background-color: var(--body-color);
}
.dashboard-filter.latest-activity .btn {
  padding: 5px 10px 5px 10px;
  min-width: auto;
  white-space: normal;
}
.dash-box .badge {
  width: 32px;
  height: 32px;
  line-height: 32px;
  padding: 0;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  border-radius: 100%;
  background-color: var(--body-color);
}
.dashboard-filter.right-side .btn {
  min-width: 76px;
}
.dashboard-filter.invoice-filter .btn {
  padding: 12px 15px;
  min-width: 250px;
  margin: 10px 35px 15px 0px !important;
}
.dashboard-filter.invoice-filter .btn .icon {
  min-height: auto;
  line-height: inherit;
}
.dashboard-filter.invoice-filter .btn .icon svg {
  max-height: inherit;
  max-width: initial;
}
.dashboard-filter.invoice-filter .btn.active h2,
.dashboard-filter.invoice-filter .btn.active h4,
.dashboard-filter.invoice-filter .btn.active p {
  color: #fff !important;
}
.dashboard-filter.invoice-filter .btn.active .progress-bar {
  background-color: #fff !important;
}

.left-border-box {
  border-left: solid 5px var(--body-color);
}
.left-border-box .content {
  line-height: normal;
}
.left-border-box .content small {
  font-size: 12px;
}
.left-border-box .badge.badge-primary {
  background-color: var(--body-color);
  border-radius: 0;
  font-weight: 600;
}
.latest-activity .btn svg g,
.latest-activity .btn svg rect,
.latest-activity .btn svg path,
.left-border-box svg g,
.left-border-box svg rect,
.left-border-box svg path {
  fill: var(--body-color);
}
svg.sort-primary-icon g,
svg.sort-primary-icon rect,
svg.sort-primary-icon path,
svg.back-icon g,
svg.back-icon rect,
svg.back-icon path,
svg.bell-icon path {
  fill: var(--body-color);
}
.shipper-icon line,
.shipper-icon path {
  stroke: var(--body-color);
}
svg.consignee-icon,
svg.agent-icon,
svg.description-icon,
svg.weight-icon,
svg.containers-icon,
svg.cargo-icon,
svg.bell-primary-icon,
svg.shipment-icon,
svg.arrow-down-icon,
svg.arrow-down-icon path {
  stroke: var(--body-color);
}
svg.agent-icon rect {
  fill: var(--body-color);
}
.latest-activity .btn.active svg path {
  fill: #fff;
}
.shipment-time-line .time-line{
  padding-left: 30px;
  position: relative;
}
.shipment-time-line .time-line .dote::before,
.shipment-time-line .time-line::after {
  content: "";
  display: inline-block;
}
.shipment-time-line .dote::before {
  border: solid 2px var(--body-color);
  background-color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  z-index: 2;
  position: absolute;
  left: 0px;
  margin-top: 3px;
}
.shipment-time-line .time-line::after {
  border-left: dashed 3px var(--body-color);
  left: 6px;
  position: absolute;
  z-index: 1;
  top: 4px;
  bottom: 3px;
}
.time-line .h6 {
  font-size: 15px !important;
}
.shipment-time-line.hot-shipment-timeline .time-line {
  padding-left: 0;
  margin-left: 30%;
}
.shipment-time-line.hot-shipment-timeline .dote {
  position: relative;
  padding-left: 40px;
}
.shipment-time-line.hot-shipment-timeline .dote::before {
  border: solid 1px var(--body-color);
  background-color: var(--body-color);
}
.shipment-time-line.hot-shipment-timeline .dote.pending::before {
  background-color: #fff;
}
.shipment-time-line.hot-shipment-timeline .time-line::after {
  left: 7px;
  border-left: solid 1px var(--body-color);
}
.shipment-time-line.hot-shipment-timeline .dote:last-child {
  margin-bottom: 0px !important;
}
.hot-shipment-timeline .status {
  position: absolute;
  top: 2px;
  left: -200px;
  width: 170px;
  font-weight: bold;
  text-align: right;
}
.need-help-box {
  margin-bottom: -1.25rem !important;
}
.count-dot {
  position: absolute;
  top: 14px;
  right: 8px;
  height: 9px;
  width: 9px;
  border-radius: 100%;
  background-color: #fff;
}
.arrow-chevron .card {
  position: relative;
  padding-left: 0px;
  padding-right: 40px;
  z-index: 1;
}
.arrow-chevron div:not(:first-child):not(:last-child) .card:before,
.arrow-chevron div:not(:first-child):not(:last-child) .card:after {
  position: absolute;
  left: -35px;
  display: block;
  content: "";
  background-color: #ededed;
  border-right: 45px solid #fff;
  width: 55px;
  height: 50%;
  z-index: -1;
}
.arrow-chevron .card:before {
  bottom: 0px;
  transform: skew(-30deg);
}
.arrow-chevron .card:after {
  top: 0;
  transform: skew(30deg);
}
.icon-primary svg {
  stroke: var(--body-color);
}
.hot-shipment-action .form-control {
  font-weight: bold;
  border: none;
  box-shadow: 0.72px 0.72px 2.51px rgb(0 0 0 / 10%);
}
.card-deck .card {
  min-height: 100%;
}
.small-box .card {
  max-width: 250px;
  min-height: 100%;
}
.progress {
  height: 0.5rem;
  background-color: #b2b2b2;
}
.progress-bar {
  border-radius: 100px;
}
.actions-dropdown.form-control {
  width: 100px;
  background-color: var(--body-color);
  color: #fff;
  border: none;
  border-radius: 0;
  font-weight: bold;
  cursor: pointer;
}
.actions-dropdown option {
  background: #fff;
  color: #333;
}
.user-name {
  height: 34px;
  width: 34px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 100%;
  color: #fff;
  font-size: 15px;
  background-color: var(--body-color);
}
.drag-drop-attachment .fileinput .thumbnail {
  border: 1px dashed var(--body-color);
  border-radius: 0;
  padding: 15px;
}
.drag-drop-attachment .btn.text-primary-custom,
.btn.text-primary-custom {
  color: var(--body-color) !important;
}
.drag-drop-attachment .close-icon {
  position: absolute;
  top: 0;
  right: 0;
  height: 25px;
  width: 25px;
  line-height: 15px;
  background-color: var(--body-color);
  border-radius: 100%;
  text-align: center;
  font-size: 14px;
  padding: 0;
}
.settings-tabs.nav-tabs {
  border-bottom: none;
}
.settings-tabs.nav-tabs .nav-link {
  font-weight: 700;
  color: var(--body-color);
  border: none;
  box-shadow: 1.7px 1.7px 5px rgb(0 0 0 / 9%);
  background-color: #fff;
  border-radius: 0;
  margin-right: 10px;
}
.settings-tabs.nav-tabs .nav-link.active {
  background-color: var(--body-color);
  color: #fff !important;
  box-shadow: none;
}
.settings-tabs ~ .tab-content .form-label {
  color: #000 !important;
}
.settings-tabs ~ .tab-content .fileinput .thumbnail {
  border: 0.5px solid var(--body-color) !important;
}
.color-box-container {
  display: flex;
  align-items: center;
}
.color-box {
  width: 100%;
  height: 12.625rem;
  max-width: 4.188rem;
  border: 1px solid transparent;
  cursor: pointer;
}
.color-box:hover {
  border: 1px solid rgb(255 255 255 / 60%);
}
.exit-btn {
  width: 100%;
  padding: 11px 15px 11px 22px !important;
  font-size: 1rem;
  font-weight: bold;
  color: #fff !important;
  height: auto;
  border: none !important;
}
.exit-btn:hover {
  background: #f2f6f5 !important;
  color: var(--body-color) !important;
  border: none !important;
}
.zoom-btn {
  border: 1px solid var(--body-color) !important;
  border-radius: 100%;
  padding: 0 !important;
  height: 25px !important;
  width: 25px;
  line-height: 25px !important;
}
.top-2 {
  position: relative;
  top: 2px;
}
.small-chips .btn.active h2,
.small-chips .btn.active h6 {
  color: #fff !important;
}

/* PDF Wrapper */
.pdf-container {
  padding: 0px 4px;
  font-size: 11px;
  color: #000;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 210mm;
  margin: 0;
}
.pdf-container table,
.pdf-container table tr td,
.pdf-container table tr th {
  border-collapse: collapse;
  border-spacing: 0px !important;
  border: none !important;
  padding: 0;
  margin: 0;
}
.pdf-container table tr th,
.pdf-container table tr td {
  padding: 0.2rem 0.4rem;
  border: none !important;
}
.pdf-checkbox {
  position: relative;
  display: inline-block;
  height: 16px;
  width: 16px;
}
.pdf-checkbox.checked::after {
  position: absolute;
  top: 1px;
  left: 5px;
  width: 5px;
  height: 9px;
  content: "";
  border: 1px solid #404141;
  border-width: 0 2px 2px 0;
  display: block;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media print {
  .pdf-container {
    width: 210mm;
  }
}
@media print {
  body {
    -webkit-print-print-color-adjust: exact;
  }
}

/*Loader*/
.loader-container {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
}
.loader-logo {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 5;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sk-chase {
  width: 55px;
  height: 55px;
  position: relative;
  margin: 0 auto;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: var(--body-color);
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  } 100%, 0% {
      transform: scale(1.0);
    }
}

/* ********************************************************************************************
    Design for a width of 1199px and below
*********************************************************************************************** */

@media only screen and (max-width: 1199px) {

  .border-left.border-primary {
    border: none !important;
  }
  .pdf-iframe iframe {
    height: 420px !important;
  }
}


/* ********************************************************************************************
    Design for a width of 992px and above
*********************************************************************************************** */

@media only screen and (min-width: 992px) {

  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
}

/* ********************************************************************************************
    Design for a width of 768px and above
*********************************************************************************************** */

@media only screen and (min-width: 768px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
}

/* ********************************************************************************************
    Design for a width of 991px and below
*********************************************************************************************** */

@media only screen and (max-width: 991px) {
  body .sidebar ~ .content-wrapper {
    padding-left: 0px;
  }
  .top-toolbar .navbar-nav .nav-item.nav-text {
    padding: 0 10px;
  }
  .top-toolbar.navbar-desktop {
    background-color: var(--body-color) !important;
  }
  .page-header .d-flex {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  body .sidebar-left .sidebar-content {
    background-color: var(--body-color);
  }
  .logo-custom {
    margin-top: 5px;
  }
  .top-toolbar.navbar-mobile.navbar-tablet .navbar-nav .nav-item a i {
    font-size: 25px;
    line-height: 2.8;
  }
  body .aside-toolbar, body .top-toolbar.navbar-mobile.navbar-tablet {
    border-bottom: 1px solid rgb(255 255 255 / 50%);
  }
  .sidebar.sidebar-left .sidebar-content {
    top: 65px;
  }
  .user-dropdown {
    margin: 0 12px !important;
  }
  .page-header h1 {
    font-size: 1.5rem;
  }
  .font-size-40 {
    font-size: 2rem!important;
  }
  .dash-box .badge {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 1.125rem;
  }
  .modal-title {
    font-size: 1.4rem !important;
  }
  .table {
    white-space: nowrap;
  }
  .page-title {
    font-size: 1.5rem !important;
    padding-left: 0px;
    margin-left: 0;
    margin-bottom: 1.25rem;
  }
  .font-size-60 {
    font-size: 2.75rem!important;
  }
  .dashboard-filter.invoice-filter .btn .icon svg {
    max-height: 70px;
  }
  .h2, h2 {
    font-size: 1.5rem !important;
  }
  .lh-58 {
    line-height: 50px;
  }
  .dashboard-filter.invoice-filter .btn {
    min-width: 230px;
    max-width: 230px;
    margin: 10px 15px 15px 0px !important;
  }
  .pagination {
    margin: 15px 0 0 !important;
  }
  .settings-tabs.nav-tabs .nav-link {
    font-size: 0.875rem;
  }
  .min-h-235 {
    min-height: auto;
  }
}

/* ********************************************************************************************
    Design for a width of 767px and below
*********************************************************************************************** */

@media only screen and (max-width: 767px) {
  .tab-pane .actions {
    position: relative !important;
  }
  .tab-pane .actions.top-right {
    right: 0px !important;
  }
  .login-box-body,
  .register-box-body {
    padding: 20px;
  }
  .login-box-body .form-control {
    height: 45px;
  }
  .login-box-body .btn {
    margin-top: 5px;
  }
  .btn.btn-lg, a:link.btn.btn-lg {
    padding: 20px 30px!important;
  }
  .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a, body .sidebar.sidebar-left .sidebar-content .main-menu .sidebar-header {
    font-size: 0.875rem;
  }
  .modal-title {
    font-size: 1.125rem !important;
  }
  .modal-header, .modal-body, .modal-footer {
    padding: 14px 20px;
  }
  .modal-dialog {
    margin: 0 15px;
  }
  .close img {
    width: 25px;
  }
  .modal-body p {
    margin: 0;
  }
  h4 {
    font-size: 1rem;
  }
  .main-menu .nav li .icons {
    width: 30px;
  }
  .modal-open .modal {
    padding-left: 0 !important;
  }
  .font-size-15 {
    font-size: 14px !important;
  }
  textarea,
  .common-details textarea {
    height: 100px !important;
  }
  .page-header h1 {
    font-size: 1.25rem;
  }
  .btn, a:link.btn {
    font-size: 12px;
    height: 30px;
    padding: 4px 15px;
  }
  .w-60 {
    max-width: 45px;
  }
  .dashboard-filter .btn .icon {
    margin-bottom: 0.25rem;
  }
  .dashboard-filter .btn .icon svg {
    max-height: 35px;
    max-width: 70px;
  }
  .dash-box br {
    display: none;
  }
  .dash-box .badge {
    width: 26px;
    height: 26px;
    line-height: 28px;
    font-size: 1rem;
    margin-left: auto !important;
  }
  .font-size-40 {
    font-size: 1.5rem!important;
  }
  .accordion-icon {
    height: 42px;
    width: 42px;
  }
  h1, h2 {
    font-size: 1.25rem;
  }
  .h6, h6 {
    font-size: 0.938rem;
  }
  .font-size-18 {
    font-size: 16px !important;
  }
  .need-help-box h4 {
    margin: 0;
  }
  .font-size-30 {
    font-size: 1.5rem!important;
  }
  .shipment-time-line.hot-shipment-timeline .time-line {
    margin-left: 0;
  }
  .hot-shipment-timeline .status {
    position: unset;
    top: 0;
    left: 0;
    width: 170px;
    text-align: left;
    display: block;
    margin-top: 10px;
  }
  body {
    font-size: 0.875rem;
  }
  .p-4 {
    padding: 1.125rem!important;
  }
  .time-line .h6 {
    font-size: 14px !important;
  }
  .breadcrumb-item.mr-3 {
    margin-right: 0.25rem!important;
  }
  .dashboard-filter {
    margin: 0;
  }
  .h2, h2 {
    font-size: 1.25rem !important;
  }
  .breadcrumb {
    padding: 0.5rem 0.875rem;
  }
  .font-size-16 {
    font-size: 0.875rem!important;
  }
  .tab-content .tab-pane .d-flex .btn {
    height: 30px;
    line-height: 13px;
    font-size: 12px;
  }
  .settings-tabs ~ .tab-content .fileinput .thumbnail {
    height: 80px !important;
  }
  .fileinput-rounded .fileinput .thumbnail {
    width: 80px !important;
  }
  .react-colorful {
    height: 150px !important;
  }
  .common-details .form-control {
    padding: 0.422rem 0.75rem !important;
  }
  .common-details select.form-control:not([size]):not([multiple]) {
    height: calc(2.063rem + 2px);
    padding: 0.422rem 0.5rem !important;
  }
  .table-top-search .form-control {
    padding-left: 35px !important;
  }
  .form-group.mb-4 {
    margin-bottom: 1rem !important;
  }
  .font-size-13 {
    font-size: 12px !important;
  }
  .table tr td:first-child, .table tr th:first-child {
    padding-left: 1rem;
  }
  .table td, .table th {
    padding: 0.55rem;
  }
  .exit-btn {
    font-size: 0.875rem !important;
    height: auto !important;
  }
  .pr-next-btn {
    padding: 0 !important;
  }
  .zoom-btn {
    font-size: 13px !important;
  }
  .rmdp-day span {
    font-size: 11px !important;
  }
  .rmdp-week-day {
    font-size: 11px !important;
  }
  .rmdp-range-picker-footer span {
    font-size: 12px !important;
  }
  .rmdp-range-picker-footer h6 {
    font-size: 12px !important;
  }
  .rmdp-day, .rmdp-week-day {
    height: 25px !important;
    width: 25px !important;
  }
  .rmdp-button {
    padding: 5px 12px !important;
  }
  .navbar-form.navbar-search {
    right: 0 !important;
  }
  .navbar-form .form-group i.close-search {
    right: 10px !important;
  }
  .btn-group .w-350  {
    width: 250px !important;
  }
  .btn-group .w-385 {
    width: 275px !important;
  }
  .pHieT .cus-line {
    padding-left: 0px;
    box-shadow: none;
  }
  .w-500{
    width: 100% !important;
  }

}

/* ********************************************************************************************
    Design for a width of 576px and Above
*********************************************************************************************** */

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 470px;
  }
  .modal-custom .modal-dialog {
    max-width: 620px;
  }
  .custom-pr-0 {
    padding-right: 0 !important;
  }
  .custom-px-0 {
    padding-right: 0  !important;
    padding-left: 0  !important;
  }
  .custom-pl-0 {
    padding-left: 0  !important;
  }
}

/* ********************************************************************************************
    Design for a width of 576px and below
*********************************************************************************************** */

@media only screen and (max-width: 576px) {
  .d-flex.align-items-center .m-l-10 {
    margin-bottom: 8px !important;
  }
  .actions.top-right li {
    margin-bottom: 8px !important;
  }
  .actions.top-right .m-l-10 .input-group,
  .actions.top-right .ml-0 .input-group {
    width: 270px !important;
  }
  .left-form-content {
    width: 100%;
    float: none;
    text-align: center;
  }
  .page-content {
    padding: 15px !important;
  }
  .page-header {
    padding: 15px 15px 0;
  }
  .login-box-footer,
  .login-box-footer label {
    font-size: 13px !important;
  }
  .login-box-footer label {
    padding-left: 25px !important;
  }
  h2, h3 {
    font-size: 1.125rem !important;
  }
  .arrow-chevron div:not(:first-child):not(:last-child) .card:before, .arrow-chevron div:not(:first-child):not(:last-child) .card:after {
    display: none;
  }
  .font-size-30 {
    font-size: 1.25rem!important;
  }
  .font-size-60 {
    font-size: 2.5rem!important;
  }
  .dashboard-filter.invoice-filter .btn .icon svg {
    max-height: 60px;
  }
  .page-title {
    font-size: 1.25rem !important;
  }
  .font-size-15 {
    font-size: 13px !important;
  }
  .p-4 {
    padding: 0.875rem!important;
  }
  .settings-tabs.nav-tabs .nav-link {
    margin-bottom: 0.5rem;
  }
  .breadcrumb li, .breadcrumb div, .breadcrumb .btn {
    margin-bottom: 0.5rem;
  }
}

/* ********************************************************************************************
    Design for a width of 360px and below
*********************************************************************************************** */

@media only screen and (max-width: 360px) {
  h4 {
    font-size: 0.938rem;
  }
  .modal-title {
    font-size: 1rem !important;
  }
  .settings-tabs ~ .tab-content .fileinput .thumbnail {
    height: 60px !important;
  }
  .fileinput-rounded .fileinput .thumbnail {
    width: 60px !important;
  }
}

/* //search bar */
.dataResult {
  /* margin-top: 5px;
  /* width: 300px; */
  /* height: 200px; */
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}

.dataItem {
  height: 50px;
  display: flex;
  margin-top: 12px;
  color: black;
}
.cus-bold{
  font-weight: 700;
}
.cus-icon{
  width: 35px;
  min-width: 35px;
}

.dataItem p {
  margin-left: 10px;
}

/* //Date Picker */
.custom-input {
  text-align: center;
  height: "24px";
  border-radius: "8px";
  font-size: "13px";
  padding: "4px 12px";
  border: 1px var(--body-color) solid;
  box-shadow: 0 0 2px var(--body-color);
}

.custom-input:focus {
  outline: none;
  border: 1px solid var(--body-color);
  box-shadow: 0 0 10px 2px var(--body-color);
}

.iconPreview{
  font-size: large;
  color: var(--body-color);
  text-align: -webkit-center;
}

.dataResult-org{
  height: 118px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}
.navbar-form.navbar-search{
  right: 300px;
}
date-picker{
  width: 100px !important;
}
.cus-security{
  height: 80px !important;
  text-align: center !important;
  font-size: 29px !important;
  color: #643489 !important;
}
.cus-security::placeholder{
  color: #00a0ed;
}

.sc-gsnTZi
{
  max-height: 200px;
  overflow-y: auto;
}

.date-picker{
  width: 150px !important;
}

.HIDE{
  display:none;
}
.cus-div-con{
  text-align: center;
  font-weight: 900;
  color: darkblue;
}

.sc-gsnTZi{
  box-shadow: 0px 10px 7px rgb(0 0 0 / 10%);
  border-top: solid 1px #ddd;
}
.sc-gsnTZi .search-icon,
.sc-gsnTZi .line{
  display: none !important;
}
.sc-gsnTZi .primary-stroke:hover{
  background-color: #fff;
}
.sc-gsnTZi > ul > li > div{
  margin-right: 13px !important;
}
.sc-gsnTZi .cus-line{
  padding-left: 5px;
}
.sc-gsnTZi > ul > li{
  padding: 0 !important;
  border-bottom: solid 1px #ddd;
}
.sc-gsnTZi .selected{
  background: transparent !important;
}
.sc-gsnTZi .dataItem{
  height: 30px !important;
}
.dataItem p{
  font-size: 13px !important;
  margin-bottom: 0 !important;
}
.navbar-form.navbar-search .wrapper{
  border-radius: 0 !important;
}

.upload-notifications .modal-dialog {
  max-width: 700px;
}

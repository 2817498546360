@import url('https://fonts.googleapis.com/css?family=Poiret+One|Source+Sans+Pro');

.main {
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.contact {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.title {
		font-family: 'Poiret One', cursive;
	font-size: 4rem;
  margin-bottom: .5rem;
	width: 100%;
}

.sub-title {
  border-top: .1rem solid #fff;
  padding: 1rem 4rem;
	text-align: center;
}

